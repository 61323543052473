<template>
  <div class="huokeArticle-container">
    <header class="header">
      <van-nav-bar
        :title="articleTitle"
        left-arrow
        @click-left="handleBlack"
        safe-area-inset-top
      >
        <template #right>
          <!-- <van-icon name="search" size="24" color="#333333" @click="loadSearchArticle"/> -->
          <icon-svg
            iconClass="iconsearch"
            @click="loadSearchArticle"
            style="height: 24px; width: 24px; color: '#333333'"
          />
        </template>
      </van-nav-bar>
      <div class="lv-one" v-if="!showAdd&&showLvOne">
        <div class="lv-one-type">
          <div :class="topType===1?'bc-color':''" @click="handleChoiceLvOne(1)">企业文章</div>
          <div :class="topType===2?'bc-color':''" @click="handleChoiceLvOne(2)">推荐文章</div>
        </div>
      </div>
      <!-- 二级 -->
      <div class="twotypeselect">
        <div class="articleType-all" v-if="!showAdd">
          <van-tabs
              @click="loadTwoType"
              v-model:active="twoTypeTab"
              swipe-threshold="1"
              color="#FF6E01"
              title-active-color="#FF6E01"
          >
            <van-tab v-if="loadNiceChoice" title="精选"></van-tab>
            <van-tab
                v-for="it in allTypeList"
                :key="it.categoryPrimaryId"
                :title="it.materialCategoryName"
            ></van-tab>
<!--            <van-tab v-if="showMore" title="更多"></van-tab>-->
          </van-tabs>
        </div>
        <div class="more-type" @click="handleMoreType" v-if="!showAdd">更多</div>
      </div>
    </header>
    <article class="article" id="huokearticle">
      <van-pull-refresh
        @refresh="onRefresh"
        v-model="refreshing"
        style="min-height: 100%"
      >
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoadMore"
          offset="50"
          v-if="loadArticle"
        >
          <article-type
            v-if="loadArticle"
            @delete-ok="deletearticle"
            :articleList="articles"
            :artType="2"
            :twoType="twoType"
            :myArticle="myArticle"
            :typesStr="articleTypeStr"
            :fromPoster="fromPoster"
            :addPoster="addPoster"
          ></article-type>
        </van-list>
      </van-pull-refresh>
    </article>
    <footer class="huoke-footer">
      <div class="add_article" @click="handleAddArticle">+</div>
      <div class="article_list_buttom2">
        <van-tabbar v-model="active" active-color="#289FFF">
          <van-tabbar-item @click="loadChoiceArticle()">
            <!-- <img :src="active ? icon.active : icon.inactive"/> -->
            <icon-svg
              iconClass="iconweixuanzhong3"
              v-show="!chioceAll"
              class="allArt"
            />
            <icon-svg
              iconClass="iconxuanzhong3"
              v-show="chioceAll"
              class="allArt"
            />
            <span>全部文章</span>
          </van-tabbar-item>
          <van-tabbar-item @click="loadChoiceArticle()">
            <!-- <img :src="active ? icon.mycaseN : icon.mycaseC"/> -->
            <icon-svg
              iconClass="iconweixuanzhong"
              v-show="!chioceMy"
              class="allArt"
            />
            <icon-svg
              iconClass="iconxuanzhong"
              v-show="chioceMy"
              class="allArt"
            />
            <span>我的文章</span>
          </van-tabbar-item>
        </van-tabbar>
      </div>
    </footer>
    <!-- 遮罩层 -->
    <van-overlay :show="showType">
      <div class="wrapper" @click.stop>
        <div class="select-like-type">
          <icon-svg iconClass="iconx" class="cancel" @click="cancelLoadMore" />
          <p class="title">选择你喜欢的类别</p>
          <div class="grid-type">
            <div
              :class="['grid-type-item', box.includes(it) ? 'bgColor' : '']"
              v-for="it in moreTypeList"
              :key="it.categoryPrimaryId"
              @click="handleChoice(it)"
            >
              {{ it.materialCategoryName }}
            </div>
            <i></i>
            <i></i>
            <i></i>
          </div>
          <div class="confirm" @click="loadConfirm">确认</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import articleType from "../../views/articleManagement/type/type.vue";
import { getArticleList,getMerchantMaterialFullCategory } from "../../api/articleManagement";
import { useRoute, useRouter } from "vue-router";
import { getMyArticleList,getMerchantManagementScope } from "../../api/huokeArticle";
import { Toast } from "vant";
export default {
  name:'huoke',
  components: {
    articleType,
  },
  activated(){
    // console.log("this is activated",sessionStorage.getItem('scrollTop'));
    document.getElementById('huokearticle').scrollTop = Number(sessionStorage.getItem('scrollTop'))
  },
  deactivated(){
    // console.log("this is deactivated");
    let scrollTop = document.getElementById('huokearticle').scrollTop
    sessionStorage.setItem('scrollTop',scrollTop)
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      addPoster:route.params.addPoster==='undefined'?'':route.params.addPoster,
      fromPoster:route.params.fromPoster==='undefined'?'':route.params.fromPoster,
      endIndex:10000,
      showLvOne:false,
      materialType:1,
      topType:Number(route.params.oneType) === 3 ? 2 : 1,
      loadNiceChoice: false,
      articleTitle: "文章列表",
      pages: 1,
      chioceAll: true,
      chioceMy: false,
      loading: false,
      finished: false,
      showMore: false,
      articleTypeStr: route.params.typesStr ? route.params.typesStr : "",
      loadArticle: false,
      active: Number(route.params.oneType) === 3 ? 1 : 0,
      allArticlecurrent: 1,
      myArticlecurrent: 1,
      twoType: Number(route.params.twoType) ? Number(route.params.twoType) : 0,
      twoTypeTab:0,
      articles: {},
      refreshing: false,
      showType: false,
      showChoice: 1,
      box: [],
      showAdd: false,
      myArticle: 0,
      moreTypeList: [],
      allTypeList: [],
    });
     Toast.loading({
      message: "加载中...",
      // forbidClick: true,
      duration: 0,
      forbidClick: true,
      overlayClass: "load-cls",
    });
    // console.log("是否来自海报------------------------",route.params.fromPoster)
    if (state.active === 0) {
      state.chioceAll = true;
    } else {
      state.chioceAll = false;
    }
    if (state.active === 1) {
      state.chioceMy = true;
    } else {
      state.chioceMy = false;
    }
    //获取文章分类和是否显示顶级栏
    const loadArticleMoreType = async () => {
      let result = await getMerchantMaterialFullCategory({
        modeId:1,
        materialType:state.topType,
      });
      state.moreTypeList = result;
      // state.allTypeList = result.slice(0, 4);
      state.allTypeList = result
      state.loadNiceChoice = true;
      state.showMore = true;

    };

    const getMerchantManagement = async () =>{
      let res = await getMerchantManagementScope()
      state.showLvOne = res
      if (!res){
        state.topType = 0
      }
      loadArticleMoreType();
    }
    getMerchantManagement()
    const loadArticleList = async () => {
      state.allArticlecurrent = 1;
      state.articleTitle = "文章列表";
      if (state.twoType === 0) {
        let data = await getArticleList({ isMaterial: 1,materialType: state.topType });
        state.articles = data.records;
        state.pages = data.pages;
        state.loadArticle = true;
      } else {
        let data = await getArticleList({
          isMaterial: 1,
          types: state.twoType,
          materialType: state.topType
        });
        state.articles = data.records;
        state.pages = data.pages;
        state.loadArticle = true;
      }
      // state.twoType = Number(route.params.twoType)
      Toast.clear()

    };
    const loadMyArticleList = async () => {
      state.loadArticle = false;
      state.myArticlecurrent = 1;
      let data = await getMyArticleList();
      state.articles = data.records;
      state.pages = data.pages;
      state.myArticle = Number(route.params.oneType);
      state.loadArticle = true;
      state.articleTitle = "我的文章";
      Toast.clear()
    };

    if (state.active === 0) {
      loadArticleList();
    } else if (state.active === 1) {
      state.showAdd = true;
      loadMyArticleList();
    }

    //加载更多
    const onLoadMore = async () => {
      if (state.active === 0) {
        if (state.twoType === 0) {
          state.loading = true;
          state.allArticlecurrent += 1;
          if (state.allArticlecurrent > state.pages) {
            state.finished = true;
          } else {
            let data = await getArticleList({
              isMaterial: 1,
              current: state.allArticlecurrent,
              materialType: state.topType
            });
            state.pages = data.pages;
            state.articles.push(...data.records);
            if (state.allArticlecurrent >= data.pages) {
              state.finished = true;
            }
          }
          state.loading = false;
        } else if (state.twoType === state.endIndex) {
          state.loading = true;
          state.allArticlecurrent += 1;
          if (state.allArticlecurrent > state.pages) {
            state.finished = true;
          } else {
            let data = await getArticleList({
              isMaterial: 1,
              types: state.articleTypeStr,
              current: state.allArticlecurrent,
              materialType: state.topType
            });
            state.pages = data.pages;
            state.articles.push(...data.records);
            if (state.allArticlecurrent >= data.pages) {
              state.finished = true;
            }
          }
          state.loading = false;
        } else {
          state.loading = true;
          state.allArticlecurrent += 1;
          if (state.allArticlecurrent > state.pages) {
            state.finished = true;
          } else {
            let data = await getArticleList({
              isMaterial: 1,
              types: state.twoType,
              current: state.allArticlecurrent,
              materialType: state.topType
            });
            state.pages = data.pages;
            state.articles.push(...data.records);
            if (state.allArticlecurrent >= data.pages) {
              state.finished = true;
            }
          }
          state.loading = false;
        }
      } else if (state.active === 1) {
        console.log("得不得行哟===================",state.pages)
        state.loading = true;
        state.myArticlecurrent += 1;
        if (state.myArticlecurrent > state.pages) {
          state.finished = true;
        } else {
          let data = await getMyArticleList({
            current: state.myArticlecurrent,
          });
          state.pages = data.pages;
          state.articles.push(...data.records);
          if (state.myArticlecurrent >= data.pages) {
            state.finished = true;
          }
        }
        state.loading = false;
      }
    };
    //取消
    const cancelLoadMore = () => {
      state.box = [];
      state.articleTypeStr = "";
      state.showType = !state.showType;
    };
    //弹出
    const loadSelectType = () => {
      state.showType = !state.showType;
    };

    //更多分类查询
    const handleChoice = (it) => {
      if (state.box.includes(it)) {
        state.box.splice(state.box.indexOf(it), 1);
        state.articleTypeStr = state.articleTypeStr
          .split(`${it.categoryPrimaryId.toString()},`)
          .join("");
      } else {
        state.box.push(it);
        if (state.articleTypeStr === "") {
          state.articleTypeStr = it.categoryPrimaryId.toString() + ",";
        } else {
          state.articleTypeStr =
          state.articleTypeStr + it.categoryPrimaryId.toString() + ",";
        }
      }
    };

    const loadConfirm = async () => {
      // state.twoTypeTab = 99999
      state.articles = {};
      state.twoTypeTab = 0
      state.loadArticle = false;
      let result = await getArticleList({
        isMaterial: 1,
        types: state.articleTypeStr,
        materialType: state.topType
      });
      state.loadArticle = true;
      state.articles = result.records;
      state.pages = result.pages;
      state.loading = false;
      state.finished = false;
      loadSelectType();
    };

    //详情返回更多分类时
    if (route.params.typesStr && state.twoType === state.endIndex) {
      state.loadArticle = false;
      getArticleList({
        isMaterial: 1,
        types: state.articleTypeStr,
        materialType: state.topType
      }).then((result) => {
        state.loadArticle = true;
        state.articles = result.records;
        state.pages = result.pages;
        state.loading = false;
        state.finished = false;
      });
    }
    //搜索
    const loadSearchArticle = () => {
      router.push({
        path: "/articleVisitors/search",
        query: {
          artType: 2,
          myType: state.active,
          myArticle: state.myArticle,
          twoType: state.twoType,
          topType:state.topType,
        },
      });
    };
    //刷新
    const onRefresh = () => {
      if (state.active === 0) {
        state.allArticlecurrent = 1;
        if (state.twoType === state.endIndex) {
          getArticleList({
            isMaterial: 1,
            types: state.articleTypeStr,
            materialType: state.topType
          }).then((data) => {
            state.articles = data.records;
            state.pages = data.pages;
          });
        } else if (state.twoType === 0) {
          getArticleList({ isMaterial: 1,materialType: state.topType }).then((data) => {
            state.articles = data.records;
            state.pages = data.pages;
          });
        } else {
          getArticleList({ isMaterial: 1, types: state.twoType,materialType: state.topType }).then(
            (data) => {
              state.articles = data.records;
              state.pages = data.pages;
            }
          );
        }
        state.finished = false;
      } else if (state.active === 1) {
        //   state.loading = false
        state.myArticlecurrent = 1;
        getMyArticleList().then((data) => {
          state.articles = data.records;
          state.pages = data.pages;
          state.refreshing = false;
          state.finished = false;
        });
      }

      state.refreshing = false;
    };

    //打开更多分类查询
    const handleMoreType = () =>{
      loadTwoType(10000)
    }
    //二级分类
    const loadTwoType = async (val) => {
      console.log("二级分类", val);
      if(val!==0&&val!==10000){
        state.allTypeList.map((it,index)=>{
          if(index+1===val){
            console.log("选中的分类",it)
            state.twoType = it.categoryPrimaryId
          }
        })
      }else {
        if(val===0){
          state.twoType = 0
        }else {
          state.twoType = val
        }
      }
      console.log("二级分类============>下标", state.twoType,state.endIndex);
      if (state.twoType === state.endIndex) {
        loadSelectType();
      } else {
        if (state.twoType === 0) {
          let data = await getArticleList({ isMaterial: 1,materialType: state.topType});
          state.articles = data.records;
          state.pages = data.pages;
        } else {
          let data = await getArticleList({
            isMaterial: 1,
            types: state.twoType,
            materialType: state.topType
          });
          state.articles = data.records;
          state.pages = data.pages;
        }
      }
      state.allArticlecurrent = 1;
      state.finished = false;
    };
    //一级分类
    const loadChoiceArticle = async () => {
      state.articles = {};
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
        overlayClass: "load-cls",
      });

      state.showChoice = state.active;
      if (state.active === 0) {
        state.chioceMy = false;
        state.chioceAll = true;
        state.articleTitle = "文章列表";
        state.myArticle = 0;
        if (state.twoType === 0) {
          let result = await getArticleList({ isMaterial: 1,materialType: state.topType });
          state.articles = result.records;
          state.pages = result.pages;
        } else {
          if (state.twoType === state.endIndex) {
            let result = await getArticleList({
              isMaterial: 1,
              types: state.articleTypeStr,
              materialType: state.topType
            });
            state.articles = result.records;
            state.pages = result.pages;
          } else {
            let result = await getArticleList({
              isMaterial: 1,
              types: state.twoType,
              materialType: state.topType
            });
            state.articles = result.records;
            state.pages = result.pages;
          }
        }
        state.showAdd = false;
        state.finished = false;
        state.allArticlecurrent = 1;
      } else {
        state.chioceAll = false;
        state.chioceMy = true;
        // state.articles = {}
        state.articleTitle = "我的文章";
        let result = await getMyArticleList();
        state.articles = result.records;
        state.pages = result.pages;
        state.myArticle = 3;
        state.showAdd = true;
        state.finished = false;
        state.myArticlecurrent = 1;
      }
      // onLoadMore()
      // state.finished = false
        Toast.clear()

    };

    const handleAddArticle = () => {
      router.push({
        path: "/articleVisitors/selectCreateType",
        // query: { myType: state.active, twoType: state.twoType, isAdd: "add" },
      });
    };
    function deletearticle(val) {
      getMyArticleList({size:state.myArticlecurrent*10}).then((data) => {
        state.articles = data.records;
        Toast.success("删除成功");
      });
      console.log(val);
    }

    const handleBlack = () => {
      // router.back()
      console.log("文章跳转创建海报",route.params.addPoster)
      if(route.params.addPoster==='true'){
        router.push({ path: "/createPoster" })
        return
      }else if (route.params.fromPoster==='true'){
        // router.push({ path: "/posterDetails" })
        router.push({ path: "/posterDetails" })
        return;
      }else {
        router.push({ path: "/" });
      }
    };
    const handleChoiceLvOne = (val) =>{
      state.topType = val
      loadArticleMoreType();
      loadChoiceArticle()
    }

    return {
      ...toRefs(state),
      getMerchantManagement,
      handleMoreType,
      handleChoiceLvOne,
      loadArticleList,
      handleBlack,
      deletearticle,
      handleAddArticle,
      loadChoiceArticle,
      loadSearchArticle,
      loadTwoType,
      handleChoice,
      loadConfirm,
      onRefresh,
      loadSelectType,
      onLoadMore,
      cancelLoadMore,
    };
  },
};
</script>

<style lang="scss" >
.huokeArticle-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .load-cls {
    background-color: red;
  }
  //遮罩层
  .van-overlay {
    z-index: 3;
    height: 100%;
    width: 100%;
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .select-like-type {
        position: absolute;
        height: 365px;
        width: 344px;
        left: 16px;
        top: 88px;
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 8px;
        background-color: #fff;
        .cancel {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 12px;
          right: 12px;
        }
        .title {
          font-size: 18px;
          font-weight: 600;
          line-height: 10px;
        }
      }
      .grid-type {
        padding-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        // border-radius: 10px;
        justify-content: space-between;
        >i{
          height: 28px;
          width: 72px;
        }
        .grid-type-item {
          height: 28px;
          width: 72px;
          margin-bottom: 8px;
          border-radius: 5px;
          font-size: 12px;
          line-height: 28px;
          background-color: #f1f3f6;
        }
        .bgColor {
          color: #ffffff;
          background-color: #ffcf23;
        }
      }
      .confirm {
        position: absolute;
        height: 28px;
        width: 72px;
        color: #ffffff;
        font-size: 12px;
        line-height: 28px;
        background-color: #ff6e01;
        border-radius: 5px;
        margin-left: 50%;
        left: -36px;
        // transform:translateX(-50%);
        bottom: 20px;
      }
    }
  }

  .header {
    height: auto;
    [class*=van-hairline]::after {
      border:none;
    }
    .van-nav-bar__arrow {
      color: #333333;
    }
    .lv-one{
      padding:0 16px;
      font-size: 14px;
      font-weight: 600;
      .lv-one-type{
        display: flex;
        justify-content: space-between;
        height: 38px;
        >div{
          line-height: 38px;
        }
        :nth-child(1){
          border-radius: 4px 0px 0px 4px;
          border: .4px solid #999999;
          flex: 1;
        }
        :nth-child(2){
          flex: 1;
          border-radius: 0px 4px 4px 0px;
          border: .4px solid #999999;
        }
        .bc-color{
          background-color: #FF6E01;
          color: #FFFFFF;
          border: none;
        }
      }
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
    .twotypeselect{
      display: flex;
      justify-content: space-between;
      .articleType-all {
        overflow-x: hidden;
        .van-tabs__wrap {
          border-bottom: 0.5px solid #f1f3f6;
        }
        .van-tab {
          color: #666666;
          font-size: 12px;
          font-weight: 600;
          padding: 0 20px;
          //flex: auto;
          //   border-bottom: 1px solid #cccccc;
        }
        .van-tab--active {
          color: #323233;
          font-size: 12px;
          font-weight: 600;
        }
      }
      //.tabs-main{
      //  flex: 1;
      //  display: flex;
      //  width: 200px;
      //  overflow-y:hidden;
      //  li{
      //    width: auto;
      //    float: left;
      //  }
      //}
      .more-type{
        width: 120px;
        height: 50px;
        color: #666666;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        line-height: 45px;
        padding: 0 10px;
      }
    }

  }
  .article {
    flex: 1;
    overflow-x: hidden;
    .van-pull-refresh {
      // padding-bottom: 200px;
      // height: 100%;
      // overflow-x: hidden;
      // z-index: -999;
    }
  }
  .huoke-footer {
    position: relative;
    height: 50px;
    .van-tabbar-item__text {
      img {
        height: 24px;
        width: 24px;
      }
    }

    .add_article {
      height: 36px;
      width: 52px;
      color: #fff;
      line-height: 36px;
      text-align: center;
      border-radius: 8px;
      font-size: 28px;
      position: fixed;
      z-index: 2;
      left: 50%;
      bottom: 7px;
      transform: translateX(-50%);
      background-color: #ff6e01;
    }
    .van-tabbar-item__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        display: block;
        margin-left: 12px;
      }
      .allArt {
        display: block;
        text-align: center;
        font-size: 20px;
      }
    }
    .article_list_buttom2 {
    }
  }
}
</style>
